import React from 'react'

const className = 'font-serif text-h2 md:text-h2md leading-tight antialiased'
export const H2ClassName = className

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  as?: 'h1'
}

const H2: React.FC<Props> = ({ children, className, as = 'h2', ...other }) => {
  const Component = as as any
  return (
    <Component
      className={H2ClassName + (className ? ` ${className}` : '')}
      {...other}
    >
      {children}
    </Component>
  )
}

export default H2
